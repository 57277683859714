import EntityForm from "@/mixins/EntityForm";
import Component, {
  mixins
} from "vue-class-component";

@Component({
  inject: {
    linkService: "linkService",
    hallService: "hallService",
    auditoriumService: "auditoriumService",
    pavilionService: "pavilionService",
    standService: "standService",
    infoDeskService: "infoDeskService",
    onDemandService: "onDemandService",
    infoBoxService: "infoBoxService",
    languageService: "languageService",
    galleryService: "galleryService",
    loungeService: "loungeService",
    infoCardPageService: "infoCardPageService",
    liveSessionService: "liveSessionService",
    platformConfigurationService: "platformConfigurationService",
  },
  watch: {
    "model.type": function (newVal, oldVal) {
      if (newVal != oldVal) {
        if (oldVal != undefined) {
          this.model.to = null;
          this.model.toCode = null;
          this.model.externalLink = null;
          this.model.subType = null;
        }
      }
      this.refreshToItems(newVal);
    }
  }
})
export default class LinkForm extends mixins(EntityForm) {
  model = {};
  platformLanguages = [];
  linkTypes = [];
  hotspotTypes = [];
  hotspotPositions = [];
  hotspotAnimations = [];
  toItems = [];
  defaultPopUpWidth = null;
  defaultPopUpHeight = null;
  defaultPopUpX = null;
  defaultPopUpY = null;

  invalidPositionsByType = {
    "Balloon": ["Center"],
    "HotspotCircle": ["Center"],
  };

  get toTargets() {
    return this.toItems.map((i) => {
      return {
        value: i.id,
        label: i.code
      };
    });
  }

  get hotspotOptionsFieldsIds() {
    const optionsByType = {
      "Balloon": [],
      "HotspotCircle": [],
      "Overlap": [
        "hotspotOverlapImage",
        "hotspotOverlapImageScale",
        "hotspotOverlapImageFadeInDirection",
        "hotspotOverlapImageAnchorPoint",
        "hotspotOverlay",
        "hotspotGradientColorCenter",
        "hotspotGradientColorOutskirt",
        "hotspotGradientDistance",
      ]
    };

    return typeof optionsByType[this.model?.hotspotType] !== "undefined"
      ? optionsByType[this.model?.hotspotType]
      : [];
  }

  prepareHotspotOptions() {
    const fieldsIds = this.hotspotOptionsFieldsIds;
    const optionsVal = {};
    for (const i in fieldsIds) {
      if (!this.model?.hotspotOptions) {
        continue;
      }
      const fieldKey = fieldsIds[i];
      if (typeof this.model?.hotspotOptions[fieldKey] === "undefined") {
        continue;
      }
      optionsVal[fieldKey] = this.model?.hotspotOptions[fieldKey];
    }
    return JSON.stringify(optionsVal);
  }

  get hotspotOptionsFields() {
    if (!this.model?.hotspotType) {
      return [];
    }
    const fieldset = {
      id: "hotspotOptions",
      legend: this.translations?.labels?.link_form_hotspotOptions,
      type: "fieldset",
      group: "object",
      collapsible: true,
      open: true,
      resetColumns: true,
    };

    const allOptions = [
      {
        id: "hotspotOverlapImage",
        label: this.translations?.labels?.link_form_hotspotOverlapImage,
        type: "media",
        mediaType: "image"
      },
      {
        id: "hotspotOverlapImageFadeInDirection",
        label: this.translations?.labels?.link_form_hotspotOverlapImageFadeInDirection,
        type: "select",
        items: [
          { value: "none", label: "none" },
          { value: "top", label: "top" },
          { value: "left", label: "left" },
          { value: "right", label: "right" },
          { value: "bottom", label: "bottom" }
        ]
      },
      {
        id: "hotspotOverlapImageScale",
        label: this.translations?.labels?.link_form_hotspotOverlapImageScale,
        type: "slider",
        step: 0.25,
        max: 5,
        min: 0.25,
      },
      {
        id: "hotspotOverlapImageAnchorPoint",
        label: this.translations?.labels?.link_form_hotspotOverlapImageAnchorPoint,
        type: "select",
        items: [
          { value: "center", label: "center" },
          { value: "top", label: "top" },
          { value: "bottom", label: "bottom" },
          { value: "right", label: "right" },
          { value: "left", label: "left" },
          { value: "top-right", label: "top-right" },
          { value: "top-left", label: "top-left" },
          { value: "bottom-right", label: "bottom-right" },
          { value: "bottom-left", label: "bottom-left" }
        ]
      },
      {
        id: "hotspotGradientColorCenter",
        label: this.translations?.labels?.link_form_hotspotGradientColorCenter,
        type: "color",
        mode: "rgba",
      },
      {
        id: "hotspotGradientColorOutskirt",
        label: this.translations?.labels?.link_form_hotspotGradientColorOutskirt,
        type: "color",
        mode: "rgba",
      },
      {
        id: "hotspotGradientDistance",
        label: this.translations?.labels?.link_form_hotspotGradientDistance,
        type: "slider",
        step: 5,
        max: 100,
        min: 5,
      },
      {
        id: "hotspotOverlay",
        label: this.translations?.labels?.link_form_hotspotOverlay,
        type: "switch",
      },
    ];

    const targetOptions = this.hotspotOptionsFieldsIds;

    fieldset.elements = allOptions.filter(o => {
      return targetOptions.includes(o.id);
    });

    if (fieldset.elements.length == 0) {
      return [];
    }

    return [fieldset];
  }

  get linkElements() {
    return [
      {
        legend: this.translations?.labels?.commonFieldset_generalData,
        type: "fieldset",
        group: "default",
        collapsible: true,
        open: true,
        resetColumns: true,
        elements: [
          {
            id: "from",
            type: "hidden"
          },
          {
            id: "width",
            label: this.translations?.labels?.link_form_width,
            type: "number",
            step: 0.25,
            hide: () => {
              return !this.alwaysVisible();
            }
          },
          {
            id: "height",
            label: this.translations?.labels?.link_form_height,
            type: "number",
            step: 0.25,
            hide: () => {
              return !this.alwaysVisible();
            }
          },
          {
            id: "x",
            label: this.translations?.labels?.link_form_x,
            type: "number",
            rules: "min:0|max:100",
            hint: this.translations?.labels?.link_form_perc_hint,
            step: 0.25,
            hide: () => {
              return !this.alwaysVisible();
            }
          },
          {
            id: "y",
            label: this.translations?.labels?.link_form_y,
            type: "number",
            rules: "min:0|max:100",
            hint: this.translations?.labels?.link_form_perc_hint,
            step: 0.25,
            hide: () => {
              return !this.alwaysVisible();
            }
          },
          {
            id: "type",
            label: this.translations?.labels?.link_form_type,
            type: "select",
            items: this.linkTypesOptions,
            required: true
          },
          {
            id: "to",
            label: this.translations?.labels?.link_form_to,
            type: "select",
            items: this.toTargets,
            required: this.needsTo,
            hide: () => {
              return !this.needsTo;
            }
          },
          {
            id: "subType",
            label: this.translations?.labels?.link_form_subType,
            type: "select",
            items: this.availableSubTypes,
            required: this.needsSubType,
            hide: () => {
              if(this.entityType === "Menu"){
                return true;
              }
              return !this.needsSubType;
            }
          },
          {
            id: "intern",
            label: this.translations?.labels?.link_form_intern,
            type: "select",
            items: this.availableUsers,
            required: this.currentTargetType?.id == "GTICall",
            clearable: this.currentTargetType?.id == "GTISwitchboard",
            hide: () => {
              if(this.entityType === "Menu" || (this.currentTargetType?.id != "GTICall" && this.currentTargetType?.id != "GTISwitchboard")){
                return true;
              }
              return false;
            }
          },
          {
            id: "popupX",
            label: this.translations?.labels?.link_form_popup_x,
            type: "number",
            rules: "min:0|max:100",
            hint: this.defaultPopUpXHint,
            step: 0.25,
            persistentHint: true,
            hide: () => {
              return !this.hasPopupResizable;
            }
          },
          {
            id: "popupY",
            label: this.translations?.labels?.link_form_popup_y,
            type: "number",
            rules: "min:0|max:100",
            hint: this.defaultPopUpYHint,
            step: 0.25,
            persistentHint: true,
            hide: () => {
              return !this.hasPopupResizable;
            }
          },
          {
            id: "popupWidth",
            label: this.translations?.labels?.link_form_popup_width,
            type: "number",
            rules: "min:0|max:100",
            hint: this.defaultPopUpWidthHint,
            step: 0.25,
            persistentHint: true,
            hide: () => {
              return !this.hasPopupResizable;
            }
          },
          {
            id: "popupHeight",
            label: this.translations?.labels?.link_form_popup_height,
            type: "number",
            rules: "min:0|max:100",
            hint: this.defaultPopUpHeightHint,
            step: 0.25,
            persistentHint: true,
            hide: () => {
              return !this.hasPopupResizable;
            }
          },
          {
            id: "externalLink",
            label: this.externalLinkLabel,
            type: "text",
            hint: this.externalLinkHint,
            required: this.needsExternalLink,
            rules: this.externalLinkRules,
            hide: () => {
              return !this.needsExternalLink;
            }
          },
          {
            id: "openInNewTab",
            label: this.translations?.labels?.link_form_openInNewTab,
            type: "switch",
            hide: () => {
              return !this.hasOpenInNewTab;
            }
          },
          {
            id: "urlParameters",
            label: this.translations?.labels?.link_form_urlParameters,
            type: "text",
            hint: this.translations?.labels?.link_form_urlParameters_hint,
            hide: () => {
              return !this.hasUrlParameters;
            },
            onChange: () => {
              try {
                // if the value is an url we take only the parameters section
                let url = new URL(this.model.urlParameters);
                this.model.urlParameters = url.search;
                return;
              } catch (_) {
                // else check that the structure il parameters like
                if (!this.model.urlParameters.startsWith("?")) {
                  this.model.urlParameters = "?" + this.model.urlParameters;
                }
                return;
              }
            }
          },
          {
            id: "autoOpen",
            label: this.translations?.labels?.link_form_type_autoOpen,
            type: "switch",
            hide: () => {
              if(this.entityType === "Menu"){
                return true;
              }
              return !this.hasAutoOpen;
            }
          },
          {
            id: "previewVideoImage",
            label: this.translations?.labels?.link_form_previewVideoImage,
            type: "media",
            mediaType: "image",
            hide: () => {
              if(this.entityType === "Menu"){
                return true;
              }
              return !this.needsImagePreview;
            }
          },
          {
            id: "icon",
            label: this.translations?.labels?.link_form_icon,
            type: "media",
            mediaType: "image",
            hide: () => {
              if(this.entityType === "Menu"){
                return true;
              }
              return !this.needsIcon;
            },
            required: this.hasNoAction,
          },
        ]
      },
      {
        legend: this.translations?.labels?.link_form_hotspot,
        type: "fieldset",
        group: "hotspot",
        collapsible: true,
        open: false,
        resetColumns: true,
        hide: () => {
          return !this.hasHotspot;
        },
        elements: [
          {
            id: "hotspotEnabled",
            label: this.translations?.labels?.link_form_hotspotEnabled,
            type: "switch"
          },
          {
            id: "hotspotType",
            label: this.translations?.labels?.link_form_hotspotType,
            type: "select",
            items: this.hotspotTypesOptions
          },
          {
            id: "hotspotPosition",
            label: this.translations?.labels?.link_form_hotspotPosition,
            type: "select",
            items: this.hotspotPositionsOptions
          },
          {
            id: "hotspotAnimation",
            label: this.translations?.labels?.link_form_hotspotAnimation,
            type: "select",
            items: this.hotspotAnimationsOptions
          },
          {
            id: "hotspotFontSize",
            label: this.translations?.labels?.link_form_hotspotFontSize,
            type: "select",
            items: this.hotspotFontSizesOptions
          },
          ...this.hotspotOptionsFields,
          {
            id: "hotspotLocalizations",
            type: "localizations",
            header: this.translations?.labels?.form_localizations,
            languages: this.platformLanguages,
            resetColumns: true,
            elements: [
              {
                id: "hotspotLabel",
                label: this.translations?.labels?.link_form_hotspotLabel,
                type: "textarea",
                required: false,
              },
            ],
          },
        ]
      }
    ];
  }

  get elements() {
    return this.linkElements;
  }

  get linkTypesOptions() {
    const lto = this.linkTypes
    .filter(l => l?.linkTypeGroup != "NoGroup")
    .map(l => {
      let groupLabel;

      switch(l?.linkTypeGroup) {
        case "Direct":
          groupLabel = this.translations?.labels?.link_form_type_direct;
          break;
        case "SpecificParameter":
          groupLabel = this.translations?.labels?.link_form_type_specificparameter;
          break;
        case "RelatedResource":
          groupLabel = this.translations?.labels?.link_form_type_relatedresource;
          break;
        case "Feature":
          groupLabel = this.translations?.labels?.link_form_type_feature;
          break;
        case "NoAction":
          groupLabel = this.translations?.labels?.link_form_type_noaction;
          break;
        default:
          groupLabel = this.translations?.labels?.link_form_type_nogroup;
      }

      return {
        value : l?.id,
        label : l?.id,
        group : groupLabel
      };

    });

    return lto;
  }

  get currentTargetType() {
    if (!this.model?.type) {
      return null;
    }

    const targetType = this.linkTypes.filter(t => t.id == this.model.type);
    if (targetType.length == 0) {
      return null;
    }

    return targetType[0];
  }

  get defaultPopUpWidthHint(){
    let defaultValue = this.defaultPopUpWidth;
    return this.translations?.labels?.link_form_perc_hint + " Default value: " + defaultValue + "%";
  }

  get defaultPopUpHeightHint(){
    let defaultValue = this.defaultPopUpHeight;
    return this.translations?.labels?.link_form_perc_hint + " Default value: " + defaultValue + "%";
  }

  get defaultPopUpXHint(){
    let defaultValue = this.defaultPopUpX;
    return this.translations?.labels?.link_form_perc_hint + " Default value: " + defaultValue + "%";
  }

  get defaultPopUpYHint(){
    let defaultValue = this.defaultPopUpY;
    return this.translations?.labels?.link_form_perc_hint + " Default value: " + defaultValue + "%";
  }


  get hasNoAction() {
    return this.currentTargetType?.id === "ImageBox";
  }

  get needsTo() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.needsTo;
  }

  get needsIcon() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.hasIcon;
  }

  get needsExternalLink() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.needsExternalLink;
  }

  get hasOpenInNewTab() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.hasOpenInNewTab;
  }

  get hasUrlParameters() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.hasUrlParameters;
  }

  get hasPopupResizable() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.hasPopupResizable;
  }

  get hasAutoOpen() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.autoOpen;
  }

  get needsImagePreview() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.needsImagePreview;
  }

  get needsSubType() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.needsSubType;
  }

  get externalLinkLabel() {
    switch (this.model?.type?.toLowerCase()) {
      case "contactform":
        return this.translations?.labels?.link_form_contactformLinkLabel;
      default: return this.translations?.labels?.link_form_externalLink;
    }
  }

  get externalLinkRules() {
    switch (this.model?.type?.toLowerCase()) {
      case "contactform":
        return "emailFormat";
      default: return undefined;
    }
  }

  get externalLinkHint() {
    switch (this.model?.type?.toLowerCase()) {
      case "videobox":
        return this.translations?.labels?.link_form_videoboxLinkGuide;
      case "contactform":
        return this.translations?.labels?.link_form_contactformLinkGuide;
      default: return "";
    }
  }

  get availableSubTypes() {
    return this.currentTargetType == null ?
      [] :
      (this.currentTargetType?.availableSubTypes ?? []).map(st => {
        return {
          value: st,
          label: st
        };
      });
  }
  get availableUsers() {
    return (this.currentTargetType == null || (this.currentTargetType.id != "GTICall" && this.currentTargetType?.id != "GTISwitchboard")) && this.model.subType ?
      [] :
      (this.currentTargetType?.users ?? [])
      .filter(u => u.categories.findIndex(c => c == this.model.subType) > -1)
      .map(u => {
        return {
          value: u.extension,
          label: u.name
        };
      });
  }

  get hasHotspot() {
    return this.currentTargetType == null ?
      false :
      this.currentTargetType.hasHotspot;
  }

  get hotspotTypesOptions() {
    return this.hotspotTypes.map(h => {
      return {
        value: h,
        label: h
      };
    });
  }

  get hotspotPositionsOptions() {
    const hotspotType = this.model?.hotspotType;
    const invalidPositions = typeof this.invalidPositionsByType[hotspotType] !== "undefined"
      ? this.invalidPositionsByType[hotspotType]
      : [];
    return this.hotspotPositions
      .filter(h => {
        return !invalidPositions.includes(h);
      })
      .map(h => {
        return {
          value: h,
          label: h
        };
      });
  }

  get hotspotAnimationsOptions() {
    return this.hotspotAnimations.map(h => {
      return {
        value: h,
        label: h
      };
    });
  }

  get hotspotFontSizesOptions() {
    return this.hotspotFontSizes.map(h => {
      return {
        value: h,
        label: h
      };
    });
  }

  get helpLink() {
    return this.translations.pageHelp.link;
  }

  alwaysVisible() {
    return true;
  }

  async refreshToItems(type) {
    this.toItems = [];
    switch (type) {
      case "InfoBox":
        this.toItems = await this.infoBoxService.list(this.queryParameters.idParent);
        break;
      case "Hall":
        this.toItems = await this.hallService.list();
        break;
      case "Auditorium":
        this.toItems = await this.auditoriumService.list();
        break;
      case "Pavilion":
        this.toItems = await this.pavilionService.list();
        break;
      case "Stand":
        this.toItems = await this.standService.list();
        break;
      case "InfoDesk":
        this.toItems = await this.infoDeskService.list();
        break;
      case "OnDemand":
        this.toItems = await this.onDemandService.list();
        break;
      case "Lounge":
      case "ChatRooms":
        this.toItems = await this.loungeService.list();
        break;
      case "Gallery":
        this.toItems = await this.galleryService.list();
        break;
      case "InfoCardPage":
        this.toItems = await this.infoCardPageService.list();
        break;
      case "SingleLiveSession":
        this.toItems = await this.liveSessionService.list(this.queryParameters.idParent);
        break;
    }
  }

  get queryParameters() {
    return this.$route.params;
  }

  get entityType() {
    return this.$route.params?.entityType ?? "";
  }

  // eslint-disable-next-line no-empty-function
  afterCreate() { }

  async created() {
    this.platformLanguages = await this.languageService.list();
    this.linkTypes = await this.linkService.types(this.entityType);
    this.hotspotTypes = await this.linkService.hotspotTypes();
    this.hotspotPositions = await this.linkService.hotspotPositions();
    this.hotspotAnimations = await this.linkService.hotspotAnimations();
    this.hotspotFontSizes = await this.linkService.hotspotFontSizes();

    const linksDefaultWidthOptions = await this.platformConfigurationService.getByName("PopupDefaultWidthPercentage");
    this.defaultPopUpWidth = linksDefaultWidthOptions?.value;

    const linksDefaultHeightOptions = await this.platformConfigurationService.getByName("PopupDefaultHeightPercentage");
    this.defaultPopUpHeight = linksDefaultHeightOptions?.value;

    const linksDefaultXOptions = await this.platformConfigurationService.getByName("PopupDefaultPositionX");
    this.defaultPopUpX = linksDefaultXOptions?.value;

    const linksDefaultYOptions = await this.platformConfigurationService.getByName("PopupDefaultPositionY");
    this.defaultPopUpY = linksDefaultYOptions?.value;

    this.afterCreate();
  }
}